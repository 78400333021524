export enum EntityType {
    Company = "companies",
    PublicOrganization = "public-organizations",
    Scientist = "scientists",
    News = "news",
    Equipment = "equipment",
    ResearchProblem = "research-problems",
    JobOffer = "job-offers",
    SpecificKnowledge = "specific-knowledge",
    Category = "categories",
    SpecialCategory = "special-categories",
    Product = "products",
    Client = "clients",
    Newsletter = "newsletters",
    MainEntities = "companies, public-organizations, scientists",
    FinancingSource = "financing-sources",
}
